import React from "react";
import "./Footer.css";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section address">
          <h4>Address</h4>
          <div className="logo-container">
            <img src={Logo} alt="Logo" className="footer-logo" />
          </div>
          <p>Serving Tampa Bay and Surrounding Areas</p>
        </div>

        <div className="footer-section quicklinks">
          <h4>Quick links</h4>
          <div className="footer-links">
            <Link to="/about">About</Link>
            <a href="https://consultlm.syncromsp.com/bookings">Book Now</a>
            <a href="https://consultlm.syncromsp.com/check_ins">
              Service Ticket
            </a>
            <Link to="/client-portal">Client Portal</Link>
          </div>
        </div>

        <div className="footer-section contact">
          <h4>Contact</h4>
          <span>
            <a href="tel:8139661802">
              <i className="fas fa-phone"></i> 813 966 1802
            </a>
          </span>
          <span>
            <br />
            <br />
            <a href="mailto:support@consultlm.com">
              <i className="fas fa-envelope"></i> support@consultlm.com
            </a>
          </span>
          <br />
          <br />
          <span>
            <i className="fas fa-clock"></i> Business Hours Mon - Fri 9a - 4p
          </span>
        </div>
      </div>

      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} LM Consulting Web App. All rights
          reserved.{" "}
          <strong>
            <Link to = '/sitemap'>Sitemap</Link>
          </strong>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
