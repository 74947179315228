import React from "react";
import { Link } from "react-router-dom";
import "./css/sitemap.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Sitemap = () => {
  return (
    <>
      <Header />
      <div className="sitemap-container">
        <div className="banner-sitemap">
            <img src="https://cdn.pixabay.com/photo/2018/07/25/18/36/ecommerce-3562005_1280.jpg" alt="sitemap"/>
        </div>
        <h1>Sitemap</h1>
        <ul>
          <li>
            <Link to="/">Services</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/pricing">Pricing & Plans</Link>
          </li>
          <li>
            <Link to="/site-projects">Site Projects</Link>
          </li>
          <li>
            <Link to="/client-portal">Client Portal</Link>
            <ul>
              <li>
                <strong>Schedule NOW</strong> -{" "}
                <a href="https://consultlm.syncromsp.com/bookings">
                  Book Your Slot
                </a>
              </li>
              <li>
                <strong>Remote Access</strong> -{" "}
                <a href="https://consultlm.rmmservice.com/auth/#/login">Connect Now</a>
              </li>
              <li>
                <strong>Service Ticket</strong> -{" "}
                <a href="https://consultlm.syncromsp.com/check_ins">
                  Raise a Ticket
                </a>
              </li>
              <li>
                <strong>Account Login</strong> -{" "}
                <a href="https://consultlm.syncromsp.com/my_profile/user_login">
                  Sign In
                </a>
              </li>
              <li>
                <strong>Teams Meeting</strong> -{" "}
                <a href="https://teams.microsoft.com/l/channel/19%3A5uDvk5ktXtgeXps_brWEhcg2fTxgpCJRZnwkOpxY1Ps1%40thread.skype/Website%20Leads?groupId=85b3cead-81b8-4dc8-8e0e-474f17138f66&tenantId=aecdb3cf-6aad-43c5-a8ec-243900f5e937&ngc=true&allowXTenantAccess=true">
                  Join Meeting
                </a>
              </li>

              <li>
                <strong>Support Links</strong>
              </li>
              <ul>
                <li>
                  <a href="https://consultlm.rmmservice.com/connect/#/9201551896">
                    NinjaOne Assist
                  </a>
                </li>
                <li>
                  <a href="https://apps.apple.com/us/app/ninjaone-assist/id6451313359">
                    iOS NinjaOne Assist
                  </a>
                </li>
                <li>
                  <a href="https://consultlm.rmmservice.com/installer/1526719042">
                    Pro Support
                  </a>
                </li>
                <li>
                  <a href="https://production.kabutoservices.com/desktop/macos/setup?token=3f6e16b0-a57e-48de-8cf2-e9a933174c59">
                    Mac Standard Support
                  </a>
                </li>
                <li>
                  <a href="https://rmm.syncromsp.com/dl/msi/MjAwMTgwNTUtMTYwODQ3MDk0NC01NDIyMC03NDYyMg==">
                    Windows Standard Support
                  </a>
                </li>
                <li>
                  <a href="https://www.passwordboss.com/download">
                    Password Boss V1
                  </a>
                </li>
                <li>
                  <a href="https://resources.ninjarmm.com/development/ninjacontrol/5.3.3267/ncinstaller-x64.exe">
                    Ninja Remote Viewer
                  </a>
                </li>
              </ul>

              <li>
                <strong>Tech Login</strong>
              </li>
              <ul>
                <li>
                  <a href="https://consultlm.rmmservice.com/#/auth/login">
                    Ninja
                  </a>
                </li>
                <li>
                  <a href="https://consultlm.syncromsp.com/users/sign_in">
                    Syncro
                  </a>
                </li>
                <li>
                  <a href="https://cumulus.sherweb.com/partners/lm-consulting">
                    Sherweb
                  </a>
                </li>
                <li>
                  <a href="https://securitytrails.com/">DNS History</a>
                </li>
                <li>
                  <a href="https://consultlm.huducloud.com/">Hudu</a>
                </li>
                <li>
                  <a href="https://qrcode.tec-it.com/en/Wifi">QR Generator</a>
                </li>
                <li>
                  <a href="https://download.teamviewer.com/download/TeamViewer_Setup.exe">
                    Teamviewer full Installer
                  </a>
                </li>
                <li>
                  <a href="https://cloud.gravityzone.bitdefender.com/">
                    Bitdefender
                  </a>
                </li>
                <li>
                  <a href="https://omada.tplinkcloud.com/login">Omada</a>
                </li>
              </ul>
            </ul>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default Sitemap;
